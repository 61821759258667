<template>
	<div class="px-4 pt-5 my-5 text-center">
		<h1 class="display-3 fw-bold">Lisa’s Homemade Marmelade</h1>
		<div class="col-lg-6 mx-auto">
			<p class="lead mb-4 mt-4">
				Hausgemacht und frisch zubereitet. Genau so schmecken unsere
				Fruchtaufstriche. Uns liegt das Besondere Etwas, die kleine Prise Liebe
				und Sorgfalt in jedem Glas am Herzen. Gekocht mit achtsam ausgesuchten
				Früchten, pur oder verfeinert mit leichten Anekdoten interessanter
				Gewürze, zaubern wir einen ganz besonderen Geschmack auf den Tisch.
				Unsere Fruchtaufstriche, mit 66 prozentigen Fruchtanteil, kommen ganz
				ohne Konservierungsstoffe und sonstigen Schnickschnack aus.
			</p>
			<p class="lead mb-4">
				Uns ist wichtig regionale und saisonale Früchte für unsere Aufstriche zu
				verwenden. Genauso sehr achten wir auf die Qualität der Früchte für
				exotischere Sorten.
			</p>
			<p class="lead mb-4">
				Unter den Kosenamen „Lieschen‘s“ präsentieren wir unsere aller ersten
				Sorten, die ab sofort erhältlich sind.
			</p>
		</div>
		<div class="overflow-hidden">
			<div class="container px-5">
				<img
					src="@/assets/lisa_blueberry.jpeg"
					class="h-100 img-fluid border rounded-3 shadow mb-4"
					alt="Example image"
					width="700"
					loading="lazy"
				/>
			</div>
		</div>
		<h1 class="mt-4 display-5 fw-bold">Wer sind WIR denn überhaupt?</h1>
		<div class="col-lg-6 mx-auto">
			<p class="lead mb-4 mt-4">
				Wir sind Lisa und David, zwei junge Menschen, denen es unglaublich viel
				Freude bereitet in der Küche zu stehen. Neue Kreationen zu entdecken,
				erfinderisch zu sein und zu tüfteln. Schon seit einigen Jahren wird
				ausgiebig Marmelade in unserer Küche selbst gekocht und Familie und
				Freunde beschenkt.
			</p>
			<p class="lead mb-4">
				Wir sind begeistert davon, wie gut das schmeckt, wie vielfältig
				Marmeladenrezeptze sein können und wollen am liebsten alle daran
				teilhaben lassen.
			</p>
		</div>

		<div class="overflow-hidden">
			<div class="container px-5">
				<img
					src="@/assets/david_and_lisa.jpeg"
					class="h-100 img-fluid border rounded-3 shadow mb-4"
					alt="Example image"
					width="700"
					loading="lazy"
				/>
			</div>
		</div>

		<div class="col-lg-6 mx-auto">
			<p class="lead mb-4 mt-4">
				Marmelade kann so viel mehr als eine Frühstückszutat sein. Man kann mit
				ihr auch ein Lächeln ins Gesicht zaubern.
			</p>
			<p class="lead fw-bold mb-4">Als Gastgeschenk auf Hochzeiten,</p>
			<p class="lead fw-bold mb-4">als personalisiertes Kundengeschenk,</p>
			<p class="lead fw-bold mb-4">als Weihnachtsgeschenk für Kollegen,</p>
			<p class="lead fw-bold mb-4">oder einfach als Dankeschön.</p>
		</div>

		<div class="overflow-hidden">
			<div class="container px-5">
				<img
					src="@/assets/gift.jpeg"
					class="h-100 img-fluid border rounded-3 shadow mb-4"
					alt="Example image"
					width="700"
					loading="lazy"
				/>
			</div>
		</div>
		<div class="col-lg-6 mx-auto">
			<p class="lead mb-4 mt-4">
				Bei uns können Sie Ihre Fruchtaufstriche ganz individuell für jeden
				Anlass gestalten lassen. Wir kochen Aufstriche mit den Früchten, die Sie
				gerne möchten und designen das Etikett auf Ihren Wunsch.
			</p>
			<p class="lead mb-4">Lassen Sie es uns wissen, wir freuen uns schon.</p>
		</div>
		<!-- end of main story -->
		<div class="d-lg-flex flex-lg-equal my-md-3 ps-md-3">
			<div
				class="
          me-md-3
          pt-3
          px-3
          pt-md-5
          px-md-5
          text-center
          overflow-hidden
          w-100
        "
			>
				<div class="my-3 py-3">
					<h2 class="display-5">Heidelbeere-Vanille</h2>
					<p class="lead">
						Einer unserer absoluten Lieblinge. Ein kräftiger Geschmack von
						Heidelbeeren, abgerundet durch eine zarte, aber intensive Note von
						Vanille. Schmeckt nicht nur gut, sondern riecht auch himmlisch!
					</p>
				</div>
				<div class="mx-auto" style="width: 80%; border-radius: 21px 21px 0 0">
					<img
						src="@/assets/HV-WEB_2.jpg"
						class="img-fluid border rounded-3 shadow-lg mb-4"
						alt="Example image"
						height="700"
						loading="lazy"
					/>
					<p>
						Inhaltsstoffe: 66% Heidelbeeren, 33% Zucker, Säuerungsmittel
						Zitronensäure, Geliermittel Pektin, Vanillemark.
					</p>
					<p>
						Nährwertangaben pro 100g: Brennwert 678 kJ/162 kcal, Fett 0,4g
						(davon gesättigte Fettsäuren &lt; 0,0g), Kohlenhydrate 38,0g (davon
						Zucker 36,9g), Eiweiß 0,5g, Salz &lt;0,0g.
					</p>
				</div>
			</div>
			<div
				class="
          me-md-3
          pt-3
          px-3
          pt-md-5
          px-md-5
          text-center
          overflow-hidden
          w-100
        "
			>
				<div class="my-3 p-3">
					<h2 class="display-5">Apfel-Rhabarber-Lavendel</h2>
					<p class="lead">
						Eine tolle Kombination aus süß und säuerlich. Die gleichen Anteile
						an Apfel und Rhabarber ergänzen sich toll. Im Abgang schmeckt man
						eine leichte, interessante Note, die sich super mit den Aromen
						verbindet - den Lavendel.
					</p>
				</div>
				<div class="mx-auto" style="width: 80%; border-radius: 21px 21px 0 0">
					<img
						src="@/assets/ARL-WEB_3.jpg"
						class="h-100 img-fluid border rounded-3 shadow-lg mb-4"
						alt="Example image"
						height="700"
						loading="lazy"
					/>
					<p>
						Inhaltsstoffe: Apfel (35%), Rhabarber (31%), Zucker (33%),
						Geliermittel Pektin, Säuerungsmittel Zitronensäure, Lavendelblüten
					</p>
					<p>
						Nährwertangaben pro 100g: Brennwert 661 kJ/158 kcal, Fett 0,1g
						(davon gesättigte Fettsäuren &lt; 0,0g), Kohlenhydrate 38,2g (davon
						Zucker 36,7g), Eiweiß 0,5g, Salz &lt; 0,0g.
					</p>
				</div>
			</div>
		</div>
	</div>
	<!-- END of story  -->

	<!-- <div class="container-lg col-xl-10 col-xxl-8 px-4 py-5">
    <div class="row align-items-center g-lg-5 py-5">
      <div class="col-lg-5 text-center text-lg-start">
        <h1 class="display-4 fw-bold lh-1 mb-3">Lisa’s Homemade Marmelade</h1>
        <p class="col-lg-10 fs-4">
          Hausgemacht und frisch zubereitet. Genau so schmecken unsere
          Fruchtaufstriche. Uns liegt das Besondere Etwas, die kleine Prise
          Liebe und Sorgfalt in jedem Glas am Herzen. Gekocht mit achtsam
          ausgesuchten Früchten, pur oder verfeinert mit leichten Anekdoten
          interessanter Gewürze, zaubern wir einen ganz besonderen Geschmack auf
          den Tisch. Unsere Fruchtaufstriche, mit 66 prozentigen Fruchtanteil,
          kommen ganz ohne Konservierungsstoffe und sonstigen Schnickschnack
          aus.
        </p>
      </div>
      <div class="col-md-10 mx-auto col-lg-7">
        <div class="p-4 p-md-5">
          <img
            src="@/assets/spoon_image_2.jpg"
            class="img-fluid border rounded-3 shadow-lg mb-4"
            alt="Example image"
            height="700"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
export default {
	mounted() {
		let recaptchaScript = document.createElement("script");
		recaptchaScript.setAttribute(
			"src",
			"https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js"
		);
		document.head.appendChild(recaptchaScript);
	},
};
</script>

<style></style>
