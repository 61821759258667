<template>
	<div>
		<button
			class="btn btn-primary"
			data-bs-toggle="modal"
			data-bs-target="#cart"
			@click="addToCart"
		>
			Add to cart
		</button>
	</div>
</template>

<script>
export default {
	name: "AddToCart",
	props: {
		name: String,
		price: String,
		productId: String,
		image: String,
	},
	data() {
		return {
			item: {
				productName: this.name,
				productPrice: this.price,
				product_id: this.productId,
				productImage: this.image,
				productQuantity: 1,
			},
		};
	},
	methods: {
		addToCart() {
			this.$store.commit("cart/addToCart", this.item);
		},
	},
};
</script>
