export default {
    namespaced: true,
    state: {
        shopHTML: 'Write about your small business here...',
        shopTitle: '',
        shopPhotoName: '',
        shopPhotoFileURL: null,
        shopPhotoPreview: null,
    },

    mutations: {},

    actions: {},
}
