<template>
	<div>
		<div>
			<div :class="{ invisible: !error }">
				<p><span>Error:</span>{{ this.errorMsg }}</p>
			</div>
			<div>
				<input type="text" placeholder="Enter Shop Title" v-model="shopTitle" />
				<div>
					<label for="shop-photo">Upload Cover Photo</label>
					<input
						type="file"
						ref="shopPhoto"
						id="shop-photo"
						accept=".png, .jpg, ,jpeg"
					/>
					<button
						@click="openPreview"
						:class="{
							'button-inactive': !this.$store.state.shopPhotoFileURL,
						}"
					>
						Preview Photo
					</button>
					<span>File Chosen: {{ this.$store.state.shopPhotoName }}</span>
				</div>
			</div>
			<div>
				<vue-editor
					:editorOptions="editorSettings"
					v-model="shopHTML"
					useCustomImageHandler
				/>
			</div>
			<div>
				<button>Publish Shop</button>
				<router-link to="#">Shop Preview</router-link>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "CreateShop",
	data() {
		return {
			error: null,
			errorMsg: null,

			editorSettings: {
				modules: {},
			},
		};
	},
};
</script>

<style></style>
