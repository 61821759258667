<template>
	<div>
		<div>
			<div>
				<div>
					<div>
						<div>
							<p>
								{{ this.modalMessage }}
							</p>
						</div>
					</div>

					<button @click="closeModal">
						Close
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["modalMessage"],
	methods: {
		closeModal() {
			this.$emit("close-modal");
		},
	},
};
</script>
