<template>
	<div>
		<Modal
			v-if="modalActive"
			:modalMessage="modalMessage"
			v-on:close-modal="closeModal"
		/>

		<div>
			<div>
				<h2>
					Reset Password
				</h2>
				<p>
					Forgot your password? Enter your email to reset it.
				</p>
			</div>
			<form action="#" method="POST">
				<div>
					<div>
						<input
							type="text"
							placeholder="Email"
							v-model="email"
							class="
                                appearance-none
                                rounded-none
                                relative
                                block
                                w-full
                                px-3
                                py-2
                                border border-gray-300
                                placeholder-gray-500
                                text-gray-900
                                rounded-t-md
                                focus:outline-none
                                focus:ring-indigo-500
                                focus:border-indigo-500
                                focus:z-10
                                sm:text-sm
                            "
						/>
					</div>
				</div>

				<div>
					<button @click.prevent="resetPassword">
						<span> </span>
						Reset
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import Modal from "../components/Modal.vue";
import { Firebase } from "../firebase/firebaseInit";

export default {
	name: "ForgotPassword",
	components: { Modal },
	data() {
		return { email: "", modalActive: false, modalMessage: "" };
	},
	methods: {
		resetPassword() {
			Firebase.auth()
				.sendPasswordResetEmail(this.email)
				.then(() => {
					this.modalMessage =
						"If your account exists, you will receive a email";
					this.modalActive = true;
				})
				.catch((err) => {
					this.modalMessage = err.message;
					this.modalActive = true;
				});
		},
		closeModal() {
			this.modalActive = !this.modalActive;
			this.email = "";
		},
	},
};
</script>
