<template>
	<div>
		<div>
			<div>
				<h2>
					Want sell your products with us!
				</h2>
				<h2>
					Create your account
				</h2>
				<p>
					Or Already have an account?
					{{ " " }}
					<router-link :to="{ name: 'Login' }">
						Login here
					</router-link>
				</p>
			</div>
			<form action="#" method="POST">
				<div>
					<div>
						<label for="firstName">First Name</label>
						<input type="text" placeholder="First Name" v-model="firstName" />
					</div>
					<div>
						<label for="lastName">Last Name</label>
						<input type="text" placeholder="Last Name" v-model="lastName" />
					</div>
					<div>
						<label for="shopUrl">Shop Url: https:kaufdeutches.de/</label>
						<input type="text" placeholder="Shop name" v-model="shopUrl" />
					</div>
					<div>
						<label for="email">Email</label>
						<input type="email" placeholder="Email" v-model="email" />
					</div>
					<div>
						<label for="password">Password</label>
						<input type="password" placeholder="Password" v-model="password" />
					</div>
				</div>
				<div v-show="error">{{ this.errorMsg }}</div>
				<div>
					<button @click.prevent="register">
						Sign Up
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { Firebase } from "../firebase/firebaseInit";
import db from "../firebase/firebaseInit";

export default {
	name: "SellerRegister",

	data() {
		return {
			firstName: "",
			lastName: "",
			shopUrl: "",
			email: "",
			password: "",
			userType: "",
			error: null,
			errorMsg: "",
		};
	},
	methods: {
		async register() {
			if (
				this.email !== "" &&
				this.password !== "" &&
				this.firstName !== "" &&
				this.lastName !== "" &&
				this.shopUrl !== ""
			) {
				this.error = false;
				this.errorMsg = "";
				const firebaseAuth = await Firebase.auth();
				const createUser = await firebaseAuth.createUserWithEmailAndPassword(
					this.email,
					this.password
				);
				const result = await createUser;

				const dataBase = db.collection("users").doc(result.user.uid);

				await dataBase.set({
					firstName: this.firstName,
					lastName: this.lastName,
					shopUrl: this.shopUrl,
					email: this.email,
					userType: "seller",
				});
				this.$router.push({ name: "Home" });
				return;
			}
			this.error = true;
			this.errorMsg = "Please fill out all the fields ! ";
			return;
		},
	},
};
</script>
