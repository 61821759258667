<template>
  <div class="container">
    <div class="row justify-content-center mt-5 mb-2 display-5">
      We are coming very soon
    </div>
    <div class="row justify-content-center mt-4 mb-2 display-6">
      please wait !!!
    </div>
    <!-- <div class="row justify-content-center mt-2 mb-5 display-6"> -->
    <!-- Days:{{ days }} - Hours:{{ hours }} - Minutes:{{ minutes }} - Seconds:{{ seconds }} -->
    <!-- {{ days }} Days {{ hours }} Hours {{ minutes }} Minutes and
		{{ seconds }} Seconds. -->
    <!-- </div> -->
    <video autoplay muted loop id="myVideo">
      <source src="@/assets/kaufdeutsches.mp4" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  data() {
    return {
      endDate: Math.trunc(Date.parse("08/31/2021 11:59 PM") / 1000),
      today: Math.trunc(new Date().getTime() / 1000),
    };
  },
  mounted: function() {
    setInterval(() => {
      this.today = Math.trunc(new Date().getTime() / 1000);
    }, 1000);
  },
  computed: {
    seconds() {
      return (this.endDate - this.today) % 60;
    },
    minutes() {
      return Math.trunc((this.endDate - this.today) / 60) % 60;
    },
    hours() {
      return Math.trunc((this.endDate - this.today) / 60 / 60) % 24;
    },
    days() {
      return Math.trunc((this.endDate - this.today) / 60 / 60 / 24);
    },
  },
};
</script>

<style scoped>
video {
  height: auto;
  width: stretch;
  object-fit: fill;
}
.btn {
  margin-left: 2px;
}
.btn {
  margin-right: 2px;
}
</style>
