<template>
	<div>
		<h3 class="d-inline">Orders</h3>
		<button
			class="btn btn-primary float-end"
			data-bs-toggle="modal"
			data-bs-target="#product"
			@click="addNew"
		>
			Add New Product
		</button>

		<div>
			<table class="table">
				<thead>
					<tr>
						<th>Name</th>
						<th>Description</th>
						<th>Price</th>
						<th>Modify</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="product in products" :key="product.id">
						<td>{{ product.data().productName }}</td>
						<td>
							<span v-html="product.data().description"></span>
						</td>
						<td>{{ product.data().price }}</td>

						<td>
							<button
								type="button"
								class="btn btn-primary"
								data-bs-toggle="modal"
								data-bs-target="#product"
								@click="editProduct(product)"
							>
								Edit</button
							><button
								class="btn btn-danger"
								@click="deleteProduct(product.id)"
							>
								Delete
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<!-- Modal -->
		<div
			class="modal fade"
			id="product"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			tabindex="-1"
			aria-labelledby="editLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="editLabel">{{ modalTitle }}</h5>
						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
							@click="modalClose"
						></button>
					</div>
					<div class="modal-body">
						<div class="row">
							<!-- main product -->
							<div class="col-md-8 ">
								<div class="mb-2">
									<input
										type="text"
										placeholder="Product Name"
										v-model="product.productName"
									/>
								</div>
								<div class="form-group">
									<vue-editor v-model="product.description"></vue-editor>
								</div>
							</div>
							<!-- product sidebar -->
							<div class="col-md-4">
								<h6>Product Details</h6>
								<hr />
								<div class="mb-2">
									<input
										type="text"
										placeholder="Price"
										v-model="product.price"
									/>
								</div>
								<div class="form-group">
									<label for="product_image">Product Images</label>
									<input
										type="file"
										@change="uploadImage"
										class="form-control"
									/>
								</div>
								<div class="form-group d-flex">
									<div
										class="p-1"
										v-for="image in product.images"
										:key="image.src"
									>
										<div class="img-wrapp mt-3">
											<img
												:style="{ width: '70px', height: 'auto' }"
												:src="image"
												alt="product image"
											/>
											<span
												class="delete-img"
												@click="deleteImage(image, index)"
												>X</span
											>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							type="button"
							class="btn btn-secondary"
							data-bs-dismiss="modal"
							@click="modalClose"
						>
							Close
						</button>
						<button
							type="button"
							class="btn btn-primary"
							@click="updateProduct()"
							v-if="modal == 'edit'"
						>
							save changes
						</button>

						<button
							class="btn btn-primary"
							@click.prevent="addProduct"
							v-if="modal == 'add'"
						>
							Add Product
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import db, { Firebase } from "../firebase/firebaseInit";

export default {
	name: "SellerOrders",
	data() {
		return {
			products: [],
			product: {
				productName: null,
				description: null,
				price: null,
				sellerId: this.$store.state.users.user.uid,
				images: [],
			},
			activeProduct: null,
			modal: null,
			modalTitle: null,
		};
	},
	methods: {
		modalClose() {
			this.clearForm();
		},
		deleteImage(img, index) {
			let image = Firebase.storage().refFromURL(img);

			this.product.images.splice(index, 1);
			image
				.delete()
				.then(() => {
					console.log("imae deleted");
				})
				.catch((error) => {
					console.log("error occurred", error);
				});
		},
		uploadImage(e) {
			if (e.target.files[0]) {
				let file = e.target.files[0];
				var storageRef = Firebase.storage().ref(
					"products/" + Math.random() + "_" + file.name
				);
				let uploadTask = storageRef.put(file);
				uploadTask.on(
					"state_changed",
					() => {},
					(error) => {
						console.log(error);
					},
					() => {
						uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
							this.product.images.push(downloadURL);
							console.log("File upload is completed");
						});
					}
				);
			}
		},

		// watcher() {
		// 	db.collection("products").onSnapshot((querySnapshot) => {
		// 		this.products = [];
		// 		querySnapshot.forEach((doc) => {
		// 			this.products.push(doc);
		// 		});
		// 	});
		// },
		updateProduct() {
			db.collection("products")
				.doc(this.activeProduct)
				.update(this.product)
				.then(() => {
					this.reset();
					this.getProducts();
				})
				.catch((error) => {
					console.error("Error updating document: ", error);
				});
		},
		editProduct(item) {
			this.modalTitle = "Edit Product";
			this.modal = "edit";
			this.product = item.data();
			this.activeProduct = item.id;
		},
		deleteProduct(productId) {
			if (confirm("Are you sure ?")) {
				db.collection("products")
					.doc(productId)
					.delete()
					.then(() => {
						this.reset();
						this.getProducts();

						console.log("Document successfully deleted!");
					})
					.catch((error) => {
						console.error("Error removing document: ", error);
					});
			} else {
				alert();
			}
		},
		getProducts() {
			db.collection("products")
				.where("sellerId", "==", this.$store.state.users.user.uid)
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						this.products.push(doc);
					});
				});
		},
		addNew() {
			this.modal = "add";
			this.modalTitle = "Add Product";
			this.clearForm();
		},
		addProduct() {
			db.collection("products")
				.add(this.product)
				.then(() => {
					this.reset();
					this.getProducts();
				})
				.catch((error) => {
					console.error("Error adding document: ", error);
				});
		},
		clearForm() {
			this.product = {
				productName: null,
				description: null,
				price: null,
				sellerId: this.$store.state.users.user.uid,
				images: [],
			};
		},
		reset() {
			Object.assign(this.$data, this.$options.data.apply(this));
		},
	},
	created() {
		this.getProducts();
	},
};
</script>
<style scoped lang="css">
.img-wrapp {
	position: relative;
}
.img-wrapp span.delete-img {
	position: absolute;
	top: -14px;
	left: -2px;
}
.img-wrapp span.delete-img:hover {
	cursor: pointer;
}
</style>
