<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <!-- New Nav bar  -->
  <nav class="navbar navbar-expand-md mb-4  navbar-light">
    <div class="container">
      <router-link class="navbar-brand" :to="{ name: 'Home' }">
        <img src="@/assets/logo.png" alt="" width="50" class="d-inline-block" />
        KAUF DEutsches
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav me-auto mb-2 mb-md-0">
          <li class="nav-item">
            <router-link
              :to="{ name: 'Products' }"
              class="nav-link active"
              aria-current="page"
              >Products</router-link
            >
          </li>
          <!-- <li class="nav-item">
						<router-link
							:to="{ name: 'Shops' }"
							class="nav-link active"
							aria-current="page"
							>Shops</router-link
						>
					</li> -->
        </ul>

        <div class="d-flex align-items-center">
          <router-link
            v-if="!user"
            :to="{ name: 'Register' }"
            class="btn btn-warning"
            >Register</router-link
          >
          <router-link
            v-if="!user"
            :to="{ name: 'Login' }"
            class="btn btn-outline-warning"
            >Login</router-link
          >

          <!-- shopping cart -->
          <a
            type="button"
            class="btn btn-text"
            data-bs-toggle="modal"
            data-bs-target="#cart"
          >
            <i class="bi bi-cart4"><Cart></Cart></i>
          </a>

          <!-- Profile dropdown -->
          <div v-if="user">
            <a
              class="dropdown-toggle d-flex align-items-center hidden-arrow"
              href="#"
              id="navbarDropdownMenuLink"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div>
                {{ this.$store.state.users.profileInitials }}
              </div>
            </a>
            <ul
              id="navbarDropdownMenuLink"
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <!-- customer dropdown -->
              <li v-if="this.$store.state.users.userType == 'customer'">
                <router-link class="dropdown-item" :to="{ name: 'Profile' }"
                  >Profile</router-link
                >
              </li>
              <li v-if="this.$store.state.users.userType == 'customer'">
                <router-link class="dropdown-item" to="#">Orders</router-link>
              </li>

              <!-- admin dropdown -->
              <li v-if="this.$store.state.users.userType == 'admin'">
                <router-link class="dropdown-item" :to="{ name: 'Admin' }"
                  >Admin Panel</router-link
                >
              </li>

              <!-- seller dropdown -->
              <li v-if="this.$store.state.users.userType == 'seller'">
                <router-link class="dropdown-item" :to="{ name: 'Seller' }"
                  >My Shop</router-link
                >
              </li>

              <li>
                <button class="dropdown-item" @click.prevent="signOut">
                  Sign out
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <!-- END OF NEW NAV BAR  -->
</template>

<script>
import { Firebase } from "../firebase/firebaseInit";
import Cart from "./Cart.vue";

export default {
  name: "Navigation",
  components: { Cart },
  methods: {
    signOut() {
      Firebase.auth()
        .signOut()
        .then(() => {
          this.$store.replaceState({ users: {}, shop: {}, productsList: {} });
          this.$router.replace({ name: "Home" });
        });
    },
  },
  computed: {
    user() {
      return this.$store.state.users.user;
    },
  },
};
</script>

<style scoped>
.btn {
  margin-left: 2px;
}
.btn {
  margin-right: 2px;
}
</style>
