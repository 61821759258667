import { createRouter, createWebHistory } from "vue-router";
import { Firebase } from "../firebase/firebaseInit";
import Home from "../views/Home.vue";
import Lisa from "../views/Lisa.vue";
import ComingSoon from "../views/ComingSoon.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import Products from "../views/Products.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import Admin from "../views/Admin.vue";
import Profile from "../views/Profile.vue";
import CreateShop from "../views/CreateShop.vue";
import Dashboard from "../views/Dashboard.vue";
import ProductsList from "../views/ProductsList.vue";
import OrdersList from "../views/OrdersList.vue";
import CustomersList from "../views/CustomersList.vue";
import SellersList from "../views/SellersList.vue";
import ShopsList from "../views/ShopsList.vue";
import Seller from "../views/Seller.vue";
import SellerRegister from "../views/SellerRegister.vue";
import SellerProfile from "../views/SellerProfile.vue";
import SellerProducts from "../views/SellerProducts.vue";
import SellerOrders from "../views/SellerOrders.vue";
import Checkout from "../views/Checkout.vue";

const routes = [
  {
    path: "//",
    name: "ComingSoon",
    component: ComingSoon,
    meta: {
      title: "ComingSoon",
    },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      title: "Home",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Login",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      title: "Register",
    },
  },
  {
    path: "/sellerregister",
    name: "SellerRegister",
    component: SellerRegister,
    meta: {
      title: "Register",
    },
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      title: "Forgot Password",
    },
  },
  {
    path: "/products",
    name: "Products",
    component: Products,
    meta: {
      title: "Products",
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      title: "Profile",
      requiresAuth: true,
      authType: "customer",
    },
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: {
      title: "Admin",
      requiresAuth: true,
      authType: "admin",
    },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          title: "Dashboard",
        },
      },
      {
        path: "productslist",
        name: "ProductsList",
        component: ProductsList,
        meta: {
          title: "Products",
        },
      },
      {
        path: "customerslist",
        name: "CustomersList",
        component: CustomersList,
        meta: {
          title: "Customers",
        },
      },
      {
        path: "orderslist",
        name: "OrdersList",
        component: OrdersList,
        meta: {
          title: "Orders",
        },
      },
      {
        path: "sellerslist",
        name: "SellersList",
        component: SellersList,
        meta: {
          title: "Sellers",
        },
      },
      {
        path: "shopslist",
        name: "ShopsList",
        component: ShopsList,
        meta: {
          title: "Shops",
        },
      },
    ],
  },
  {
    path: "/seller",
    name: "Seller",
    component: Seller,
    meta: {
      title: "Seller",
      requiresAuth: true,
      authType: "seller",
    },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          title: "Dashboard",
        },
      },
      {
        path: "sellerproducts",
        name: "SellerProducts",
        component: SellerProducts,
        meta: {
          title: "Products",
        },
      },
      {
        path: "sellerorders",
        name: "SellerOrders",
        component: SellerOrders,
        meta: {
          title: "Orders",
        },
      },
      {
        path: "sellerprofile",
        name: "SellerProfile",
        component: SellerProfile,
        meta: {
          title: "Profile",
        },
      },
      {
        path: "createshop",
        name: "CreateShop",
        component: CreateShop,
        meta: {
          title: "CreateShop",
        },
      },
    ],
  },
  {
    path: "/lieschen",
    name: "Lieschen",
    component: Lisa,
    meta: {
      title: "Lieschen",
    },
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
    meta: {
      title: "Checkout",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const currentUser = Firebase.auth().currentUser;
  const authType = to.meta.authType;

  const store = require("../store");

  document.title = `${to.meta.title} | kaufdeutsches `;

  if (requiresAuth && !currentUser) {
    next({ name: "Login" });
  } else if (requiresAuth && currentUser) {
    if (store.default.state.users.userType == authType) {
      next();
    } else {
      next({ name: "Home" });
    }
  } else {
    next();
  }
});

export default router;
