<template>
  <!-- <div class="container"> -->
  <div
    id="carouselExampleIndicators"
    class="carousel  carousel-dark slide"
    data-bs-ride="carousel"
  >
    <!-- <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div> -->
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="container">
          <img
            src="@/assets/banner_1.png"
            class=" img-fluid"
            alt="Example image"
            loading="lazy"
          />
        </div>
      </div>
      <div class="carousel-item">
        <div class="container">
          <img
            src="@/assets/banner_2.png"
            class="h-100 img-fluid"
            alt="Example image"
            loading="lazy"
          />

          <p><a class="btn btn-lg btn-primary" href="#">Shop Now</a></p>
        </div>
      </div>
      <div class="carousel-item">
        <div class="container">
          <img
            src="@/assets/banner_3.png"
            class="h-100 img-fluid"
            alt="Example image"
            loading="lazy"
          />

          <p><a class="btn btn-lg btn-primary" href="#">Open a Shop</a></p>
        </div>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExampleIndicators"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselExampleIndicators"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
  <!-- </div> -->
</template>

<style scoped>
video {
  height: stretch;
  width: stretch;
  object-fit: fill;
}
/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

body {
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: #5a5a5a;
}

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */
/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
/* .carousel-item {
  height: 22rem;
} */
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 22rem;
}

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}

/* Style the button and place it in the middle of the container/image */
.container .btn {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: rgb(255, 208, 0);
  color: rgb(0, 0, 0);
  font-size: 2.5vw;
  padding: 0.5vw 1vw;
  border: none;
  cursor: pointer;
  border-radius: 0.5vw;
}
</style>
