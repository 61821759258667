<template>
	<Navigation v-if="!navigation" />
	<router-view />
	<Footer v-if="!navigation" />
</template>

<script>
import Navigation from "./components/Navigation.vue";
import Footer from "./components/Footer.vue";
import { Firebase } from "./firebase/firebaseInit";

export default {
	name: "app",
	components: { Navigation, Footer },
	data() {
		return { navigation: null };
	},
	created() {
		Firebase.auth().onAuthStateChanged((user) => {
			this.$store.commit("users/updateUser", user);
			if (user) {
				this.$store.dispatch("users/getCurrentUser");
			}
		});
		this.checkRoute();
	},
	methods: {
		checkRoute() {
			if (
				this.$route.name === "Login" ||
				this.$route.name === "Register" ||
				this.$route.name === "ForgotPassword" ||
				this.$route.name === "Admin" ||
				this.$route.name === "Dashboard" ||
				this.$route.name === "ProductsList" ||
				this.$route.name === "CustomersList" ||
				this.$route.name === "OrdersList" ||
				this.$route.name === "SellersList" ||
				this.$route.name === "ShopsList" ||
				this.$route.name === "Seller" ||
				this.$route.name === "SellerRegister" ||
				this.$route.name === "SellerProfile" ||
				this.$route.name === "SellerProducts" ||
				this.$route.name === "SellerOrders" ||
				this.$route.name === "CreateShop" ||
				this.$route.name === "ComingSoon"
			) {
				this.navigation = true;
				return;
			}
			this.navigation = false;
		},
	},
	watch: {
		$route() {
			this.checkRoute();
		},
	},
};
</script>
