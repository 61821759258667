<template>
	<header
		class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow"
	>
		<router-link class="navbar-brand" :to="{ name: 'Home' }">
			<img src="@/assets/logo.png" alt="" width="30" class="d-inline-block" />
			Kauf Deutsches
		</router-link>
		<button
			class="navbar-toggler position-absolute d-md-none collapsed"
			type="button"
			data-bs-toggle="collapse"
			data-bs-target="#sidebarMenu"
			aria-controls="sidebarMenu"
			aria-expanded="false"
			aria-label="Toggle navigation"
		>
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="text w-100 text-white px-3" type="text">
			Shop Admin Panel
		</div>
		<div class="navbar-nav">
			<div class="nav-item text-nowrap">
				<a class="nav-link px-3" @click="signOut">Sign out</a>
			</div>
		</div>
	</header>

	<div class="container-fluid">
		<div class="row">
			<nav
				id="sidebarMenu"
				class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
			>
				<div class="position-sticky pt-3">
					<ul class="nav flex-column">
						<li class="nav-item">
							<router-link
								class="nav-link active"
								aria-current="page"
								:to="{ name: 'Dashboard' }"
							>
								<span><i class="bi bi-house-door"></i></span>
								Dashboard
							</router-link>
						</li>
						<li class="nav-item">
							<router-link class="nav-link" :to="{ name: 'SellerProducts' }">
								<span><i class="bi bi-cart4"></i></span>
								Products
							</router-link>
						</li>
						<li class="nav-item">
							<router-link class="nav-link" :to="{ name: 'SellerOrders' }">
								<span><i class="bi bi-card-checklist"></i></span>
								Orders
							</router-link>
						</li>
					</ul>

					<h6
						class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted"
					>
						<span>Settings</span>
					</h6>
					<ul class="nav flex-column mb-2">
						<li class="nav-item">
							<router-link class="nav-link" :to="{ name: 'SellerProfile' }">
								Account
							</router-link>
						</li>
						<li class="nav-item">
							<router-link class="nav-link" :to="{ name: 'CreateShop' }">
								Shop
							</router-link>
						</li>
					</ul>
				</div>
			</nav>

			<main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
				<router-view />
			</main>
		</div>
	</div>
</template>

<script>
import { Firebase } from "../firebase/firebaseInit";

export default {
	name: "Seller",

	methods: {
		signOut() {
			Firebase.auth()
				.signOut()
				.then(() => {
					this.$store.replaceState({ users: {}, shop: {}, productsList: {} });
					this.$router.replace({ name: "Home" });
				})
				.catch((err) => {
					console.log(err);
				});
		},
	},
};
</script>

<style lang="css">
.sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	padding: 48px 0 0;
	box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 767.98px) {
	.sidebar {
		top: 5rem;
	}
}

.sidebar-sticky {
	position: relative;
	top: 0;
	height: calc(100vh - 48px);
	padding-top: 0.5rem;
	overflow-x: hidden;
	overflow-y: auto;
}

.sidebar .nav-link {
	font-weight: 500;
	color: #333;
}

.sidebar .nav-link {
	margin-right: 4px;
	color: #727272;
}

.sidebar .nav-link:hover,
.sidebar .nav-link.active {
	color: #2470dc;
}

.sidebar-heading {
	font-size: 0.75rem;
	text-transform: uppercase;
}

.navbar .navbar-toggler {
	top: 0.25rem;
	right: 1rem;
}
</style>
