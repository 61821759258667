import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Firebase } from "./firebase/firebaseInit";
import Vue3Editor from "vue3-editor";

let app;
Firebase.auth().onAuthStateChanged(() => {
	if (!app) {
		createApp(App)
			.use(router)
			.use(store)
			.use(Vue3Editor)
			.mount("#app");
	}
});
