<template>
	<div>
		<div>
			<div>
				<h2>
					Create your account
				</h2>
				<p>
					Or Already have an account?
					{{ " " }}
					<router-link :to="{ name: 'Login' }">
						Login here
					</router-link>
				</p>
			</div>
			<div class="row">
				<div class="col-md-4">
					<form action="#" method="POST">
						<div>
							<div>
								<input
									type="text"
									placeholder="First Name"
									v-model="firstName"
								/>
							</div>
							<div>
								<input type="text" placeholder="Last Name" v-model="lastName" />
							</div>
							<div>
								<input type="email" placeholder="Email" v-model="email" />
							</div>
							<div>
								<input
									type="password"
									placeholder="Password"
									v-model="password"
								/>
							</div>
						</div>
						<div v-show="error">{{ this.errorMsg }}</div>
						<div>
							<button @click.prevent="register">
								Sign Up
							</button>
						</div>
					</form>
				</div>
				<div class="col-md-4">
					<p>
						Register as a seller here
						{{ " " }}
						<router-link :to="{ name: 'SellerRegister' }">
							Register here
						</router-link>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Firebase } from "../firebase/firebaseInit";
import db from "../firebase/firebaseInit";

export default {
	name: "Register",

	data() {
		return {
			firstName: "",
			lastName: "",
			email: "",
			password: "",
			userType: "",
			error: null,
			errorMsg: "",
		};
	},
	methods: {
		async register() {
			if (
				this.email !== "" &&
				this.password !== "" &&
				this.firstName !== "" &&
				this.lastName !== ""
			) {
				this.error = false;
				this.errorMsg = "";
				const firebaseAuth = await Firebase.auth();
				const createUser = await firebaseAuth.createUserWithEmailAndPassword(
					this.email,
					this.password
				);
				const result = await createUser;

				const dataBase = db.collection("users").doc(result.user.uid);

				await dataBase.set({
					firstName: this.firstName,
					lastName: this.lastName,
					email: this.email,
					userType: "customer",
				});
				this.$router.push({ name: "Home" });
				return;
			}
			this.error = true;
			this.errorMsg = "Please fill out all the fields ! ";
			return;
		},
	},
};
</script>
