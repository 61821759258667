import { Firebase } from "../../firebase/firebaseInit";
import db from "../../firebase/firebaseInit";

export default {
	namespaced: true,
	state: {
		user: null,
		userType: null,
		profileEmail: null,
		profileFirstName: null,
		profileLastName: null,
		profileId: null,
		profileInitials: null,
		shopUrl: null,
	},

	mutations: {
		updateUser(state, payload) {
			state.user = payload;
		},

		setProfileInfo(state, doc) {
			state.profileId = doc.id;
			state.profileEmail = doc.data().email;
			state.profileFirstName = doc.data().firstName;
			state.profileLastName = doc.data().lastName;
			state.userType = doc.data().userType;
			state.shopUrl = doc.data().shopUrl;
		},

		setProfileInitials(state) {
			state.profileInitials =
				state.profileFirstName.match(/(\b\S)?/g).join("") +
				state.profileLastName.match(/(\b\S)?/g).join("");
		},
		changeFirstName(state, payload) {
			state.profileFirstName = payload;
		},
		changeLastName(state, payload) {
			state.profileLastName = payload;
		},
		changeShopUrl(state, payload) {
			state.shopUrl = payload;
		},
	},

	actions: {
		async getCurrentUser({ commit }) {
			const dataBase = await db
				.collection("users")
				.doc(Firebase.auth().currentUser.uid);
			const dbResults = await dataBase.get();
			commit("setProfileInfo", dbResults);
			commit("setProfileInitials");
		},
		async updateUserSettings({ commit, state }) {
			const dataBase = await db.collection("users").doc(state.profileId);
			await dataBase.update({
				firstName: state.profileFirstName,
				lastName: state.profileLastName,
				shopUrl: state.shopUrl,
			});
			commit("setProfileInitials");
		},
	},
};
