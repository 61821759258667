<template>
	<div class="container">
		<div class="row">
			<ProductCard
				:product="product"
				v-for="product in products"
				:key="product.id"
			/>
		</div>
	</div>
</template>

<script>
import ProductCard from "../components/ProductCard.vue";
import db from "../firebase/firebaseInit";

export default {
	name: "Products",
	components: { ProductCard },
	data() {
		return {
			products: [],
		};
	},
	methods: {
		getProducts() {
			db.collection("products")
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						this.products.push(doc);
					});
				});
		},
	},
	created() {
		this.getProducts();
		console.log(this.products);
	},
};
</script>
