<template>
	<div class="col-sm-6 col-md-4 mr-2">
		<div class="card mb-5" style="width: 18rem">
			<img
				class="card-img-top"
				:src="product?.data().images"
				:alt="product.imageAlt"
			/>
			<div class="card-body">
				<h5 class="card-title">
					{{ product?.data().productName }}
				</h5>
				<p class="card-text">
					<span v-html="product?.data().description"></span>
				</p>
				<p>{{ productPrice }}</p>

				<AddToCart
					:image="getImage(product.data().images)"
					:product-id="product.id"
					:price="product.data().price"
					:name="product.data().productName"
				></AddToCart>
			</div>
		</div>
	</div>
</template>

<script>
import AddToCart from "../components/AddToCart.vue";
const formatter = new Intl.NumberFormat("de-DE", {
	style: "currency",
	currency: "EUR",
});

export default {
	name: "ProdcutCard",
	props: ["product"],
	components: { AddToCart },
	computed: {
		productPrice() {
			return formatter.format(this.product?.data().price);
		},
	},
	methods: {
		getImage(images) {
			return images[0];
		},
	},
};
</script>
