import { createStore } from "vuex";
import cart from "../store/modules/cart";
import users from "../store/modules/users";
import shop from "../store/modules/shop";
import createPersistedState from "vuex-persistedstate";

const store = createStore({
	modules: {
		cart,
		users,
		shop,
	},
	plugins: [
		createPersistedState({
			storage: window.sessionStorage,
		}),
	],
});

export default store;
