<template>
  <div class="container">
    <Hero />
    <div>
      <router-link to="/lieschen">Go to Lieschen Shop</router-link>
    </div>
    <!-- Products Section -->
    <div>
      <div>
        <h2>
          Products
        </h2>

        <div class="container">
          <div class="row">
            <ProductCard
              :product="product"
              v-for="product in products"
              :key="product.id"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "../components/ProductCard.vue";
import Hero from "../components/Hero.vue";
import db from "../firebase/firebaseInit";

export default {
  name: "Products",
  components: { ProductCard, Hero },
  data() {
    return {
      products: [],
    };
  },
  methods: {
    getProducts() {
      db.collection("products")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.products.push(doc);
          });
        });
    },
  },
  created() {
    this.getProducts();
  },
};
</script>

<style scoped>
.btn {
  margin-left: 2px;
}
.btn {
  margin-right: 2px;
}
</style>
