<template>
	<div>
		<div>
			<h2>
				Sign in to your account
			</h2>
			<p>
				Or Don't have an account?
				{{ " " }}
				<router-link :to="{ name: 'Register' }">
					Register here
				</router-link>
			</p>
		</div>
		<form action="#" method="POST">
			<input type="hidden" name="remember" value="true" />
			<div>
				<div>
					<input type="text" placeholder="Email" v-model="email" />
				</div>
				<div>
					<input type="password" placeholder="Password" v-model="password" />
				</div>
			</div>
			<div v-show="error">{{ this.errorMsg }}</div>
			<div>
				<div>
					<input id="remember-me" name="remember-me" type="checkbox" />
					<label for="remember-me">
						Remember me
					</label>
				</div>

				<div>
					<router-link :to="{ name: 'ForgotPassword' }">
						Forgot your password?
					</router-link>
				</div>
			</div>

			<div>
				<button class="btn btn-primary" @click.prevent="signIn">
					Sign in
				</button>
			</div>
		</form>
	</div>
</template>

<script>
import { Firebase } from "../firebase/firebaseInit";

export default {
	name: "Login",

	data() {
		return { email: "", password: "", error: null, errorMsg: "" };
	},
	methods: {
		signIn() {
			Firebase.auth()
				.signInWithEmailAndPassword(this.email, this.password)
				.then(() => {
					this.error = false;
					this.errorMsg = "";
					this.$router.push({ name: "Home" });
				})
				.catch((err) => {
					this.error = true;
					this.errorMsg = err.message;
				});
		},
	},
};
</script>
