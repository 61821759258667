<template>
	<div>
		customers
	</div>
</template>
<script>
export default {
	name: "CustomersList",
};
</script>
<style lang=""></style>
