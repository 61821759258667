<template>
	<div>
		<Modal
			v-if="modalActive"
			:modalMessage="modalMessage"
			v-on:close-modal="closeModal"
		/>
		<div>
			<div>
				<h2>
					Account Settings
				</h2>
			</div>
			<form action="#" method="POST">
				<div>
					<div>
						<input type="text" id="firstName" v-model="firstName" />
					</div>
					<div>
						<input type="text" id="lastName" v-model="lastName" />
					</div>
					<div>
						<input disabled type="text" id="email" v-model="email" />
					</div>
				</div>
				<!-- <div v-show="error">{{ this.errorMsg }}</div> -->
				<div>
					<button @click.prevent="updateProfile">
						Save Changes
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import Modal from "../components/Modal.vue";
export default {
	name: "Profile",
	components: { Modal },
	data() {
		return { modalActive: null, modalMessage: "Changes were saved" };
	},
	methods: {
		closeModal() {
			this.modalActive = !this.modalActive;
		},
		updateProfile() {
			this.$store.dispatch("users/updateUserSettings");
			this.modalActive = !this.modalActive;
		},
	},
	computed: {
		firstName: {
			get() {
				return this.$store.state.users.profileFirstName;
			},
			set(payload) {
				this.$store.commit("users/changeFirstName", payload);
			},
		},
		lastName: {
			get() {
				return this.$store.state.users.profileLastName;
			},
			set(payload) {
				this.$store.commit("users/changeLastName", payload);
			},
		},
		email() {
			return this.$store.state.users.profileEmail;
		},
	},
};
</script>
