export default {
	namespaced: true,
	state: {
		cart: [],
	},

	mutations: {
		addToCart(state, payload) {
			let isFound = state.cart.find(
				(product) => product.product_id == payload.product_id
			);

			if (isFound) {
				isFound.productQuantity++;
			} else {
				state.cart.push(payload);
			}
		},

		removeFormCart(state, item) {
			let index = state.cart.indexOf(item);
			state.cart.splice(index, 1);
		},
	},

	getters: {
		totalPrice: (state) => {
			let total = 0;
			state.cart.filter((item) => {
				total += item.productPrice * item.productQuantity;
			});
			return total;
		},
	},

	actions: {},
};
