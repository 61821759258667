<template>
	<div class="container mt-5 pt-5">
		<div class="row">
			<div class="col-md-9">
				<h4 class="py-4">Checkout Page</h4>
				<ul>
					<li
						v-for="cartItem in this.$store.state.cart.cart"
						:key="cartItem.product_id"
					>
						<img class="cartImage" :src="cartItem.productImage" alt="" />

						<h5>
							{{ cartItem.productName }}
							<span class="float-right" @click="removeItem">
								X
							</span>
						</h5>
						<p class="mt-0">{{ price(cartItem.productPrice) }}</p>
						<p class="mt-0">Quantity: {{ cartItem.productQuantity }}</p>
					</li>
				</ul>
			</div>
			<div class="col-md-3 ">
				Total Price : {{ price(this.$store.getters["cart/totalPrice"]) }}
			</div>
		</div>
	</div>
</template>

<script>
const formatter = new Intl.NumberFormat("de-DE", {
	style: "currency",
	currency: "EUR",
});
export default {
	name: "Checkout",
	methods: {
		price(x) {
			return formatter.format(x);
		},
		removeItem() {
			this.$store.commit("cart/removeFormCart", this.cartItem);
		},
	},
};
</script>
