<template>
	<div class="modal fade" id="cart" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Shopping Cart</h5>
					<button
						type="button"
						class="btn-close"
						data-bs-dismiss="modal"
						aria-label="Close"
					></button>
				</div>
				<div class="modal-body">
					<p v-if="item.length == 0">Your shopping cart is empty</p>
					<ul v-if="item.length > 0">
						<li
							v-for="cartItem in this.$store.state.cart.cart"
							:key="cartItem.product_id"
						>
							<img class="cartImage" :src="cartItem.productImage" alt="" />

							<h5 class="mt-0">
								{{ cartItem.productName }}
								<span class="float-right" @click="removeItem">
									X
								</span>
							</h5>
							<p class="mt-0">{{ price(cartItem.productPrice) }}</p>
							<p class="mt-0">Quantity: {{ cartItem.productQuantity }}</p>
						</li>
					</ul>
				</div>
				<div class="modal-footer">
					<button
						type="button"
						class="btn btn-secondary"
						data-bs-dismiss="modal"
						@click="continueShopping"
					>
						Continue Shopping
					</button>
					<button type="button" class="btn btn-primary" @click="goToCheckout">
						Checkout
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
const formatter = new Intl.NumberFormat("de-DE", {
	style: "currency",
	currency: "EUR",
});

export default {
	name: "Cart",
	computed: {
		item() {
			return this.$store.state.cart.cart;
		},
	},
	methods: {
		price(x) {
			return formatter.format(x);
		},
		goToCheckout() {
			this.$router.push({ name: "Checkout" });
		},
		continueShopping() {
			this.$router.push({ name: "Products" });
		},

		removeItem() {
			this.$store.commit("cart/removeFormCart", this.cartItem);
		},
	},
};
</script>

<style>
.modal-content {
	color: black;
}
.cartImage {
	width: 50px;
	height: 50px;
}
</style>
