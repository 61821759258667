import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
	apiKey: "AIzaSyCkBPZT0HAzml26begMwIG4nIB7xlecdK0",
	authDomain: "kaufdeutsches.firebaseapp.com",
	projectId: "kaufdeutsches",
	storageBucket: "kaufdeutsches.appspot.com",
	messagingSenderId: "379429234987",
	appId: "1:379429234987:web:900b782bfb6ef67d0e3d6e",
	measurementId: "G-0615HSVX5W",
};

export const Firebase = firebase.initializeApp(firebaseConfig);

const timestamp = firebase.firestore.FieldValue.serverTimestamp;
export { timestamp };
export default Firebase.firestore();
